import { config } from "~/data/config";
import { clientRepo } from "~/server/apis/client_repo";
import { GetGiftBoxV3Response } from "~/server/types/gift";
import { Header } from "~/types";
import { clearCookie, setCookie } from "~/utils/client_cookie";
import { generateDeviceVerificationToken } from "~/utils/common";

export type PlutoRevealLandingRouteData = {
  giftBoxMetadata: GetGiftBoxV3Response;
  coinsCount?: number;
  sessionId: string | null;
  isRtu: boolean;
};

export function getPlutoReceiverSessionIdStorageKey(giftingKey: string) {
  return `${giftingKey}_pluto_receiver_sessionId`;
}

export const getPlutoRevealLandingRouteData = async (props: {
  giftingKey: string;
  isPreview: boolean;
}): Promise<PlutoRevealLandingRouteData> => {
  if (props.isPreview) {
    return {
      giftBoxMetadata: {
        plutoGiftDetails: await clientRepo.getPlutoGiftPublic(props.giftingKey),
      } as GetGiftBoxV3Response,
      isRtu: false,
      sessionId: null,
    };
  }

  let deviceVerificationToken: string | undefined = undefined;
  try {
    deviceVerificationToken = await generateDeviceVerificationToken();
  } catch (error) {
    console.log("Error getting device verification token", error);
  }

  clearCookie(getPlutoReceiverSessionIdStorageKey(props.giftingKey));

  try {
    let giftBoxMetadata = await clientRepo.getGiftBoxV3(
      props.giftingKey,
      {
        [Header.FpDeviceToken]: deviceVerificationToken ?? "",
      },
      {
        isUnauthenticated: true,
      }
    );
    let isRtu = true;

    let sessionId = giftBoxMetadata.sessionId;
    let coinsCount: number | undefined = undefined;
    if (sessionId) {
      setCookie({
        key: getPlutoReceiverSessionIdStorageKey(props.giftingKey),
        value: sessionId,
        expiryInMinutes: config.sesssionDurationInMinutes.gift_receiver,
      });

      let coinsSummary = await clientRepo.getCoinsSummary({
        [Header.SessionId]: sessionId,
      });
      coinsCount = coinsSummary.totalAvailable;
      isRtu = coinsSummary.totalConsumed > 0;
    }
    return {
      giftBoxMetadata: giftBoxMetadata,
      coinsCount: coinsCount,
      sessionId: sessionId,
      isRtu: isRtu,
    };
  } catch (e) {
    throw e;
  }
};
